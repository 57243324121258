<template>
  <div class="outer-wrapper">
    <Modal
      fullscreen
      footer-hide
      v-model="ifModal"
      :closable="false"
      :mask-closable="false"
      :z-index="999999999"
    >
      <div slot="header" class="modal-header">
        {{ steps[currentStat].title }}
      </div>
      <div class="line-wrapper" v-show="currentStat === 0">
        <div class="temp_tab">
          <a href="/conclusion/risk_l">返回风险点数据库</a>
        </div>
        <div class="line-tag-wrapper">
          <span
            class="line-tag"
            :key="one.value"
            v-for="one in total_line_list"
            @click="choose_line(one)"
            :style="{ borderBottom: '6px solid ' + one.line_color }"
            >{{ one.text }}</span
          >
        </div>
        <div class="line-img-wrapper">
          <img class="line-img" :src="imgSrc.line" alt />
        </div>
      </div>
      <div class="catagory-option-wrapper" v-show="currentStat === 1">
        <div class="temp_tab">
          <a href="/risk_nav">返回</a>
        </div>
        <choice-box
          v-for="(element, index) in catagory_option"
          :selist="choosedOptions.selectedList"
          :main_title="element.option_title"
          :choices="element.value_list"
          :key="element.key_name + index"
          :key_name="element.key_name"
          @click_f="click_fu"
        ></choice-box>
        <selected-box
          :selist="part_selected_list"
          @minus_f="minus_fu2"
        ></selected-box>
        <div class="button-wrapper">
          <span class="main-button" @click="next_step">下一步</span>
        </div>
      </div>
      <div class="catagory-option-wrapper" v-show="currentStat === 2">
        <div class="temp_tab">
          <a href="/risk_nav">返回</a>
        </div>
        <choice-box
          v-for="(element, index) in catagory_option"
          :selist="choosedOptions.selectedList"
          :main_title="element.option_title"
          :choices="element.value_list"
          :key="element.key_name + index"
          :key_name="element.key_name"
          @click_f="click_fu"
        ></choice-box>
        <selected-box
          :selist="part_selected_list"
          @minus_f="minus_fu2"
        ></selected-box>
        <div class="button-wrapper">
          <span class="main-button" type="primary" @click="go_res"
            >查看结果</span
          >
        </div>
      </div>
      <div v-show="currentStat === 3" class="total-chart-wrapper">
        <div class="temp_tab">
          <a href="/risk_nav">返回</a>
        </div>
        <div class="single-chart-wrapper">
          <degree-level
            :choosedOptions="choosedOptions"
            :drawAgain="updateCount"
            :esize="echartSize"
          ></degree-level>
        </div>
        <div class="single-chart-wrapper">
          <correct-stat
            :choosedOptions="choosedOptions"
            :drawAgain="updateCount"
            :esize="echartSize"
          ></correct-stat>
        </div>
        <div class="single-chart-wrapper">
          <major-system
            :choosedOptions="choosedOptions"
            :drawAgain="updateCount"
            :esize="echartSize"
          ></major-system>
        </div>
        <div class="single-chart-wrapper">
          <risk-sort
            :choosedOptions="choosedOptions"
            :drawAgain="updateCount"
            :esize="echartSize"
          ></risk-sort>
        </div>
        <div class="single-chart-wrapper">
          <correct-demand
            :choosedOptions="choosedOptions"
            :drawAgain="updateCount"
            :esize="echartSize"
          ></correct-demand>
        </div>
        <div class="list-page-wrapper">
          <list-with-page
            :tableParams="tableParams"
            @handlePage="handlePage"
          ></list-with-page>
        </div>
        <div class="more_to">
          <a href="/conclusion/risk_l">点击查看更多风险点数据&nbsp;>></a>
        </div>
      </div>
    </Modal>
    <div class="cover-div" v-show="ifCover">
      <div class="link-index-button link-button" @click="closeCover">
        进入主页
        <i class="light"></i>
      </div>
      <div class="link-explorer-button link-button" @click="toExplorer">
        风险点导航
        <i class="light"></i>
      </div>
    </div>
  </div>
</template>
<script>
import choiceBox from "@/components/Common/ChoiceBox.vue";
import listWithPage from "@/components/Common/ListWithPage.vue";
import degreeLevel from "@/components/RiskResult/Risk/Charts/DegreeLevel.vue";
import correctStat from "@/components/RiskResult/Risk/Charts/CorrectStat.vue";
import riskSort from "@/components/RiskResult/Risk/Charts/RiskSort.vue";
import majorSystem from "@/components/RiskResult/Risk/Charts/MajorSystem.vue";
import correctDemand from "@/components/RiskResult/Risk/Charts/CorrectDemand.vue";
import selectedBox from "@/components/Common/SelectedBox.vue";
import riskListExpand from "./RiskListExpand.vue";

/*import {Array,Object} from '@/libs/lib.js'*/

let userToken = localStorage.getItem("current_user_token");

export default {
  name: "riskExplorer",
  components: {
    choiceBox,
    listWithPage,
    degreeLevel,
    correctStat,
    riskSort,
    majorSystem,
    correctDemand,
    selectedBox,
  },
  data() {
    return {
      ifCover: false,
      ifModal: true,
      lineAuth: JSON.parse(localStorage.getItem("line_auth")),
      steps: [
        // { title: "请选择城市", value: "city" },
        { title: "请选择线路", value: "line" },
        { title: "请选择风险数据特征", value: "riskClass" },
        { title: "请选择风险状态跟踪", value: "correctStat" },
        { title: "图表一览", value: "dataResult" },
      ],
      currentStat: 0,

      catagory_list: [
        { title: "城市及线路", content: [] },
        { title: "风险数据特征", content: [] },
        { title: "风险状态跟踪", content: [] },
      ],
      tableParams: {
        Tpage: {
          currentPage: 1,
          currentSize: 20,
        },
        loading: true,
        columnsFormat: [
          {
            type: "expand",
            width: 50,
            render: (h, params) => {
              return h(riskListExpand, {
                props: {
                  row: params.row,
                },
              });
            },
          },
          {
            title: "线路名称",
            key: "line_name",
            width: 90,
            align: "center",
            render: (h, p) => {
              let url = "/baseInfo/line_c/" + p.row.line_id;
              return h(
                "a",
                {
                  attrs: {
                    href: url,
                    target: "_blank",
                  },
                },
                p.row.line_name
              );
            },
          },
          {
            title: "评价时间",
            key: "evaluate_year",
            width: 90,
            align: "center",
          },
          {
            title: "风险点",
            key: "risk_content",
            align: "center",
            minWidth: 120,
            render: (h, p) => {
              let url = "/conclusion/risk_c/" + p.row.id.toString();
              return h(
                "a",
                {
                  attrs: {
                    href: url,
                    target: "_blank",
                  },
                },
                p.row.risk_content
              );
            },
          },
          {
            title: "风险类别",
            key: "sort",
            width: 110,
            align: "center",
            render: (h, p) => {
              return h("span", p.row.sort.replace(new RegExp("|", "g"), ""));
            },
            filters: [
              {
                label: "A",
                value: "A",
              },
              {
                label: "B",
                value: "B",
              },
              {
                label: "C",
                value: "C",
              },
              {
                label: "D",
                value: "D",
              },
              {
                label: "E",
                value: "E",
              },
            ],
            filterMethod(value, row) {
              return row.sort.indexOf(value) > -1;
            },
          },
          /**{
                        title: '风险点说明',
                        key: 'description',
                        tooltip: "true"
                    },**/
          {
            title: "分值",
            key: "score",
            sortable: true,
            width: 100,
            align: "center",
            filters: [
              {
                label: ">60",
                value: 1,
              },
              {
                label: "≤60",
                value: 2,
              },
            ],
            filterMultiple: false,
            filterMethod(value, row) {
              if (value === 1) {
                return row.score > 60;
              } else if (value === 2) {
                return row.score < 60;
              }
            },
          },
          {
            title: "专业子系统",
            key: "major_system",
            width: 120,
            align: "center",
            filters: [
              {
                label: "供电",
                value: "供电",
              },
              {
                label: "土建",
                value: "土建",
              },
              {
                label: "信号",
                value: "信号",
              },
              {
                label: "车辆",
                value: "车辆",
              },
              {
                label: "通信",
                value: "通信",
              },
              {
                label: "安全管理",
                value: "安全管理",
              },
              {
                label: "机电",
                value: "机电",
              },
              {
                label: "运营组织",
                value: "运营组织",
              },
              {
                label: "消防",
                value: "消防",
              },
            ],
            filterMethod(value, row) {
              return row.major_system.indexOf(value) > -1;
            },
          },
          /** {
                         title: '相关类/专业接口',
                         key: 'involve_interface',
                         width: 120,
                         align: "center",
                         filters: [
                             {
                                 label: '供电',
                                 value: '供电'
                             },
                             {
                                 label: '土建',
                                 value: '土建'
                             },
                             {
                                 label: '信号',
                                 value: '信号'
                             },
                             {
                                 label: '车辆',
                                 value: '车辆'
                             },
                             {
                                 label: '通信',
                                 value: '通信'
                             },
                             {
                                 label: '安全',
                                 value: '安全'
                             },
                             {
                                 label: '机电',
                                 value: '机电'
                             },
                             {
                                 label: '运营',
                                 value: '运营'
                             },
                             {
                                 label: '消防',
                                 value: '消防'
                             },
                         ],
                         filterMethod(value, row) {
                             return row.interface.indexOf(value) > -1;
                         }
                     },**/
          {
            title: "严重度等级",
            key: "degree_level",
            width: 120,
            align: "center",
            filters: [
              {
                label: "可接受",
                value: "可接受",
              },
              {
                label: "需重视",
                value: "需重视",
              },
              {
                label: "严重的",
                value: "严重的",
              },
              {
                label: "非常严重的",
                value: "非常严重的",
              },
              {
                label: "灾难性的",
                value: "灾难性的",
              },
            ],
            filterMethod(value, row) {
              return row.degree_level.indexOf(value) > -1;
            },
          },

          /** {
                         title: '对策建议',
                         key: 'solution_proposal'
                     },**/
          {
            title: "整改要求",
            key: "correct_demand",
            width: 110,
            align: "center",
            filters: [
              {
                label: "计划整改",
                value: "计划整改",
              },
              {
                label: "急需整改",
                value: "急需整改",
              },
              {
                label: "专项研究",
                value: "专项研究",
              },
              {
                label: "加强维护管理",
                value: "加强维护管理",
              },
              {
                label: "加强人员培训",
                value: "加强人员培训",
              },
            ],
            filterMethod(value, row) {
              return row.correct_demand.indexOf(value) > -1;
            },
          },

          /** {
                         title: '归档编号',
                         width: 100,
                         align: "center",
                         key: 'archive_no'
                     },**/
          {
            title: "整改落实状态",
            width: 140,
            align: "center",
            key: "risk_correct_stat",
            filters: [
              {
                label: "整改中",
                value: "整改中",
              },
              {
                label: "已完成",
                value: "已完成",
              },
            ],
            filterMethod(value, row) {
              return row.risk_correct_stat.indexOf(value) > -1;
            },
          },
        ],
        contentData: [],
        totalItems: 0,
        noDataText: "数据加载中...",
        tableWidth: 1230,
        pageSizeOptions: [20, 50, 100, 200, 400],
      },
      choosedOptions: {
        selectedList: [],
        keyword: "",
      },
      ch_list: [
        /**{'option_title': "归属城市",'key_name':'city','value_list':[{'text':'上海市','value':'上海市','id':'city1','children':[{ 'text': '7号线', 'value': '7号线', 'id': 'sh7','color': '#ed6f00'},{ 'text': '11号线', 'value': '11号线', 'id': 'sh11' ,'color':'#871c2b'},{ 'text': '10号线', 'value': '10号线', 'id': 'sh10','color':'#c6afd4' },]},{ 'text': '长春市', 'value': '长春市', 'id': 'city2' ,'child':[{ 'text': '3号线', 'value': '3号线', 'id': 'chch3','color': '#ed6f09'},{ 'text': '1号线', 'value': '1号线', 'id': 'chch1' ,'color':'#871c9b'},{ 'text': '8号线', 'value': '8号线', 'id': 'chch8','color':'#c6afd8' },]},{ 'text': '南京市', 'value': '南京市', 'id': 'city3' },]},
                {'option_title': "线路名称",'key_name':'line_name','value_list': []},
                {'option_title': "严重等级",'key_name':'grade','value_list': [{ 'text': '可接受', 'value': '可接受', 'id': 'class3','stat':'' },{ 'text': '需重视', 'value': '需重视', 'id': 'class2' },{ 'text': '严重', 'value': '严重', 'id': 'class1' },{ 'text': '非常严重', 'value': '非常严重', 'id': 'class4' },{ 'text': '灾难性', 'value': '灾难性', 'id': 'class5','stat':'disable' },]},
            **/
      ],
      selected_list: [],
      part_selected_list: [],
      total_line_list: [],
      selected_city: "重庆市",
      updateCount: 1,
      list_name: "riskList",
      echartSize: { height: "450px", width: "600px" },
    };
  },
  created() {
    this.get_option_list();
  },
  mounted() {},
  computed: {
    catagory_option: function () {
      let temp = [];
      for (let ele of this.ch_list) {
        if (ele.catagory === this.steps[this.currentStat].value) {
          temp.push(ele);
        }
      }
      return temp;
    },
    selected_list_text: function () {
      let temp = "——" + this.selected_city;
      for (let i of this.selected_list) {
        temp += "(" + i.text + ")";
      }
      return temp;
    },
    imgSrc: function () {
      let temp = {
        map: process.env.BASE_URL + "img/chinamap.jpg",
        line: process.env.BASE_URL + "img/" + this.selected_city + ".jpg",
      };
      return temp;
    },
  },
  methods: {
    closeCover: function () {
      this.ifCover = false;
    },
    toExplorer: function () {
      this.ifCover = false;
      this.ifModal = true;
    },
    get_option_list() {
      this.$axios
        .post(this.$url + "/ask_for_option", {
          askData: this.list_name,
          userToken: userToken,
        })
        .then((response) => {
          this.ch_list = response.data;
          for (let ele of this.ch_list) {
            if (ele.key_name === "line_code") {
              ele.value_list.sort(function (a, b) {
                return (
                  parseInt(a.value.split("-")[1]) -
                  parseInt(b.value.split("-")[1])
                );
              });
              ele.value_list.push({ value: "net", text: "线网", id: "net" });
              this.total_line_list = ele.value_list;
            }
          }
          /*console.log(this.ch_list);
                        for(let key of [0,1]){
                        if(this.ch_list[key].key_name==='line_id'){
                            this.ch_list[key].value_list.sort(function(a,b){return parseInt(a.value.split('-')[1])-parseInt(b.value.split('-')[1])});
                        }
                    }*/
        });
    },
    get_data() {
      this.tableParams.loading = true;
      this.tableParams.noDataText = "数据加载中...";
      this.$axios
        .post(this.$url + "/ask_for_data", {
          page: this.tableParams.Tpage,
          choosedOptions: this.choosedOptions,
          askData: this.list_name,
          userToken: userToken,
        })
        .then(
          (response) => (
            (this.tableParams.contentData = response.data.res_record),
            (this.tableParams.totalItems = response.data.total_n),
            (this.tableParams.loading = false),
            (this.tableParams.noDataText = "暂无数据"),
            (this.updateCount += 1)
          )
        );
    },
    handlePage(value) {
      this.tableParams.Tpage = value;
      this.get_data();
    },
    click_fu(value) {
      let tmp_key = this.choosedOptions.selectedList.indexOfObject(value);
      let temp_key_part = this.part_selected_list.indexOfObject(value);
      if (tmp_key == -1) {
        this.part_selected_list.push(value);
        this.choosedOptions.selectedList.push(value);
      } else {
        this.part_selected_list.splice(temp_key_part, 1);
        this.choosedOptions.selectedList.splice(tmp_key, 1);
      }
    },
    minus_fu2(value) {
      let tmp_key = this.choosedOptions.selectedList.indexOfObject(value);
      let tmp_key_part = this.part_selected_list.indexOfObject(value);
      this.choosedOptions.selectedList.splice(tmp_key, 1);
      this.part_selected_list.splice(tmp_key_part, 1);
    },

    choose_line(value) {
      let temp = {
        value: value.value,
        key_name: "line_code",
        text: value.text,
      };
      let tmp_key = this.choosedOptions.selectedList.indexOfObject(temp);
      if (tmp_key == -1) {
        this.choosedOptions.selectedList.push(temp);
      } else {
        this.choosedOptions.selectedList.splice(tmp_key, 1);
      }
      this.currentStat += 1;
      this.steps[1].title += this.selected_list_text;
    },
    next_step() {
      this.currentStat += 1;
      this.steps[2].title += this.selected_list_text;
    },
    go_res() {
      this.currentStat += 1;
      this.get_data();
      this.updateCount += 1;
      this.steps[3].title += this.selected_list_text;
    },
  },
};
</script>
<style scoped>
.cover-div {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #111111;
  top: 0;
  left: 0;
  z-index: 999999999999;
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -khtml-opacity: 0.9;
}

.link-button {
  width: 200px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 100px;
  right: 0;
  background-color: rgb(32, 102, 167);
  text-align: center;
  color: rgb(250, 249, 249);
  font-size: 20px;
  font-family: SimHei;
  font-weight: 700;
  border-top: 1px solid #1971a8;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.light {
  cursor: pointer;
  position: absolute;
  left: -180px;
  top: 0;
  width: 180px;
  height: 50px;
  background-image: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  transform: skewx(-25deg);
  -o-transform: skewx(-25deg);
  -moz-transform: skewx(-25deg);
  -webkit-transform: skewx(-25deg);
}

.link-button:hover .light {
  left: 180px;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.link-index-button {
  bottom: 130px;
}

.link-explorer-button {
  top: 100px;
}

.outer-wrapper {
}

.line-tag {
  display: inline-block;
  margin: 20px;
  width: 55px;
  height: 36px;
  overflow: hidden;
  text-align: center;
  line-height: 28px;
  border-bottom: 4px solid #87caed;
  font-size: 14px;
  cursor: pointer;
}

.line-tag:hover {
  color: #000;
  box-shadow: 1px 1px 2px #999;
}

.line-wrapper {
  width: 100%;
  margin-top: 60px;
  padding: 0 20%;
}

.map-wrapper {
  width: fit-content;
  margin: 0 auto;
  position: relative;
  margin-top: 50px;
}

.mapImg {
  width: 800px;
}

.city-b {
  position: absolute;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.city-b:hover {
  color: #2d8cf0;
  font-size: 26px;
}

.catagory-option-wrapper {
  margin: 0 auto;
  margin-top: 100px;
  width: 60%;
  min-width: 1000px;
}

.single-chart-wrapper {
  display: inline-block;
  margin: 5px;
}

.total-chart-wrapper {
  margin: 0 auto;
  width: 1300px;
  margin-top: 20px;
  text-align: center;
  overflow: hidden;
}

.button-wrapper {
  text-align: center;
  height: 60px;
}

.main-button {
  display: block;
  margin: 50px auto;
  cursor: pointer;
  width: 160px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  background-color: #993333;
  opacity: 0.8;
  border-radius: 5px;
  color: #ddd;
  border: none;
}

.main-button:hover {
  opacity: 0.9;
  border: none;
  color: #fff;
}

.modal-header {
  text-align: center;
  font-size: 26px;
  height: 64px;
  line-height: 50px;
  color: #888;
  position: relative;
  z-index: 999;
  background: #fff;
  margin-bottom: -14px;
  padding-bottom: 14px;
}
.more_to {
  padding: 20px 30px 300px 0;
  text-align: right;
  font-size: 16px;
}

.list-page-wrapper {
  width: fit-content;
  box-shadow: 2px 2px 1px #aaaaaa;
  border: 1px solid #e7e7e7;
  padding: 10px 20px;
}

.temp_tab {
  z-index: 9999999999;
  top: 15px;
  right: 40px;
  width: 100%;
  position: fixed;
  text-align: right;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
}
.temp_tab > a {
  color: #993333;
  opacity: 0.8;
}
.temp_tab > a:hover {
  opacity: 0.95;
}
.line-tag-wrapper {
  float: left;
  width: 30%;
  border: 1px solid #f7f7f7;
  box-shadow: 1px 1px 2px #eee;
  height: 100%;
}

.line-img-wrapper {
  float: right;
  width: 69%;
  border: 1px solid #f7f7f7;
  box-shadow: 1px 1px 2px #eee;
}
.line-img {
  width: 100%;
}
</style>